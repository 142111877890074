import * as JsSearch from "js-search";


const useJsSearch = (posts) => {
  // Search configuration
  const dataToSearch = new JsSearch.Search("id");
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("id");

  // Fields to search
  dataToSearch.addIndex("title");
  //dataToSearch.addIndex("author"]);
  dataToSearch.addIndex("body");

  // Map types and filter out empty nodes
  dataToSearch.addDocuments(posts?.nodes);

  const search = (query) => {
    return dataToSearch.search(query);
  }

  return { search };
}

export default useJsSearch
