import React, { useContext, useState } from "react"
import { LanguageContext } from "../../context"
import Banner from "../banner"
import LessonList from "../school/lesson-list"
import Button from "../button-visit-us"
import Button2 from "../button-generic"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import PageTopContent from "../home-top-content"
import { useFlexSearch } from "react-use-flexsearch"
import { useLunr } from "react-lunr"
import { Index } from "lunr"
import Filters from "../search/filters"
import Section from "../section"
import ServiceItem from "../services/service-item"
import Pagination from "../pagination"

const SchoolPage = (props) => {

  const {
    post,
    bannerHeight="408px",
    //localSearchPages,
    schoolPosts,
    promoted,
    buttonBanner,
    lang,
    pageContext,
    categories,
    pageInfo,
    initialQuery,
    refreshList
  } = props

  const { t } = useContext(LanguageContext)
  const breakpoints = useBreakpoint()


  return(
    <div className="page-blog">
    <Banner
      post={post}
      height={bannerHeight}
      backgroundColor="green"
    />

     <section className="section-blog-list-filters hide">
     <div className="container p-0">
      <Filters
           buttons={[
             <Button2
              className="btn-all-blog-posts p-0"
              title={t("Tots els Posts")}
              wrapperClass="col-2- p-l-0 p-r-0 all-ministries-wrapper m-r-32"
              action="/blog"
              />,
             <Button2
              className="btn-dropdown filter-blog-post"
              type="dropdown"
              title={t("Totes les Categories")}
              options={categories}
              wrapperClass="col-2- p-l-0 p-r-0 all-dropdown-wrapper"
              action="/blog"
              />,
            ]}
           //searchQuery={searchQuery}
           //setSearchQuery={setSearchQuery}
           action="/blog"
           marginbottom="m-b-43"
           marginTop="m-t-28"
           hasSearch={true}
           searchPlaceholder="Cerca per post"
           initialQuery={initialQuery}
           refreshList={refreshList}
       />
       </div>
      </section>

       <LessonList
        schoolPosts={schoolPosts}
        lang={pageContext?.langcode}
        className="blog-list m-b-108 p-t-80"
        classContainer="container p-0"
        classRow="col-12 col-md-3"
        includeTags={true}
       />
      {!initialQuery &&
       <section className="section-pagination m-b-80 hide">

         <Pagination
          pageInfo={pageInfo}
          alias={post?.path?.alias}
        />
       </section>
       }
     </div>

  )
}

const SectionToKnowUs = (props) => {
  const {title, post, imagePos} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left"){
  return(
    <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
       <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
          <img
            src={post?.relationships?.field_meet_image_1?.image_style_uri?._653_432}
            alt={post?.field_meet_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
          <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_field_meet_body_1?.value}} />
          <div className="row">
             <div className="container">
                <div className="col-12 button-cta align-left">
                   <Button
                      className="btn btn-default btn-outline-visit-us bordeaux"
                      title={post?.field_meet_cta_1?.title}
                      />
                </div>
             </div>
          </div>
       </div>
    </div>
  )
  }

  if(imagePos === "right"){
    if(breakpoints.xs){
      return(
        <div className="row img-left toknowus-item m-b-120 medium-m-b-114">
           <div className="col-12 col-sm-6 background-bordeux first-item max-width-683 image-wrapper">
              <img
                src={post?.relationships?.field_meet_image_2?.image_style_uri?._653_432}
                alt={post?.field_meet_cta_2?.title}
                className="m-r--24"
              />
           </div>
           <div className="col-12 col-sm-6 p-l-80 p-r-169 medium-p-48-24 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_meet_body_2?.value}} />
              <div className="row">
                 <div className="container">
                    <div className="col-12 button-cta align-left">
                       <Button
                          className="btn btn-default btn-outline-visit-us bordeaux"
                          title={post?.field_meet_cta_2?.title}
                          />
                    </div>
                 </div>
              </div>
           </div>
        </div>
      )
    }else{
      return(
        <div className="row img-right toknowus-item pastoral-team">
           <div className="col-12 col-sm-6 p-l-169 p-r-82 m-r-3p medium-p-24-48 small-p-0">
              <div className="m-b-32" dangerouslySetInnerHTML={{__html: post?.field_meet_body_2?.value}} />
              <div className="row">
                 <div className="container">
                    <div className="col-12 button-cta align-left">
                       <Button2
                          className="btn btn-default btn-outline-visit-us bordeaux"
                          title={post?.field_meet_cta_2?.title}
                          onClick={() => navigate("/equip-pastoral")}
                          />
                    </div>
                 </div>
              </div>
           </div>
           <div className="col-12 col-sm-6 background-bordeux second-item max-width-683 small-p-0">
              <img
                src={post?.relationships?.field_meet_image_2?.image_style_uri?._653_432}
                alt={post?.field_meet_cta_2?.title}
                className="m-r--24"
              />
           </div>
        </div>
      )
    }
  }
}



const SectionToVisitUs = (props) => {
  const {title, post, imagePos, pos, t} = props
  const breakpoints = useBreakpoint()

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83">
       <div className="col-6 first-item max-width-683">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-6 p-l-56 p-r-169 medium-p-l-r-25">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0-" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <div className="row  second m-b-66- medium-m-r-2 p-t-208 medium-p-t-0">
        <div className="col-md-6 second-first m-w-625 p-l-r-0- m-r-75- p-l-12 m-r-63 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
          </div>
        </div>
        <div className="col-md-6 second-second m-t--304 m-w-625 p-l-r-0 ">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_2?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
          </div>

          <div className="third p-t-80">
          <div className="ministry-image background-beig read-more m-b-42">
            <img
              src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
              alt={post?.field_visit_cta_3?.title}
              className="m-t--24 m-l-112 m-w-540"
            />
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more green"
             onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
          </div>
          <div className="ministry-title-desc">
            <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
            <div className="unida-social-media">
              <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
            </div>
          </div>
          </div>
        </div>


      </div>
    )

}

const SectionToVisitUsMobile = (props) => {
  const {title, post, imagePos, pos, t} = props

if(imagePos === "left" && pos === 1){
  return(
    <div className="row img-left visit-us-item m-b-120 medium-m-b-83 first medium-p-l-r-24 small-m-b-20">
       <div className="col-12 col-sm-6 first-item max-width-683 small-m-b-8">
          <img
            src={post?.relationships?.field_visit_image_1?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_1?.title}
            className="m-r--24"
          />
       </div>
       <div className="col-12 col-sm-6">
          <div className="m-b-32 m-t-88 medium-m-t-0 small-m-t-0 small-m-b-0" dangerouslySetInnerHTML={{__html: post?.field_visit_body_1?.value}} />
       </div>
    </div>
  )
  }


    return(
      <>
      <div className="row second small-m-b-20"> {/* first */}
        <div className="col-12 col-sm-12 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_3?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-12 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_3?.value}} />
        </div>
      </div>

      <div className="row third"> {/* second */}
        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_2?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_2?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div dangerouslySetInnerHTML={{__html: post?.field_visit_body_2?.value}} />
        </div>
      </div>

      <div className="row forth"> {/* third */}

        <div className="col-12 col-sm-6 ministry-image background-beig read-more m-b-42 medium-m-w-348 medium-m-b-82">
          <img
            src={post?.relationships?.field_visit_image_4?.image_style_uri?._653_432}
            alt={post?.field_visit_cta_3?.title}
            className="m-t--24 m-l-112 m-w-540 medium-m-l-24 medium-m-w-348"
          />
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more green"
           onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
        <div className="col-12 col-sm-6 ministry-title-desc medium-p-l-64 medium-p-r-24 small-p-l-r-0">
          <div className="m-b-8" dangerouslySetInnerHTML={{__html: post?.field_visit_body_4?.value}} />
          <div className="unida-social-media">
            <div dangerouslySetInnerHTML={{__html: post?.field_visit_social_media_body?.value}} />
          </div>
        </div>

      </div>
      </>
    )

}

const SectionConnectUs = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80' : ''} medium-m-b-40 small-m-0 small-m-b-32`}>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>
      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc">
            <div className="m-b-16" dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Veure més")}
             icon="icons-32-x-32-generic-plus"
             iconSize={16}
             iconColor="#ff0"
             iconClassName="icon"
             className="r-more bordeaux right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-4' : 'col-sm-3'} col-6 ministry-item`}>
        <div>
          <div className="ministry-image background-white small-m-b-4">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc">
          <div className="m-b-16" dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Veure més")}
           icon="icons-32-x-32-generic-plus"
           iconSize={16}
           iconColor="#ff0"
           iconClassName="icon"
           className="r-more bordeaux right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      <div className={`${breakpoints.md ? 'col-sm-2' : 'col-sm-3'}`}></div>

    </div>
  )
}

const SectionMakeDifference = (props) => {
  const {
    t,
    pos,
    imageFirst,
    titleFirst,
    descFirst,
    urlFirst,
    imageSecond,
    titleSecond,
    descSecond,
    urlSecond
  } = props

  const breakpoints = useBreakpoint()

  return(
    <div className={`row small-p-l-r-0 ${ pos === 1 ? 'm-b-80-' : ''} medium-m-b-40 small-m-0`}>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item small-m-b-24`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageFirst}
              alt={titleFirst}
              className="m-r--24"
            />
          </div>
        </div>
        <div className="p-r-60 small-p-0">
          <h2 className="title ministry-title m-b-8"> {titleFirst} </h2>
          <div className="ministry-desc m-b-31">
            <div dangerouslySetInnerHTML={{__html: descFirst }} />
          </div>
            <Button2
             title={t("Troba un ministeri")}
             className="r-more green right"
             //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
             />
        </div>
      </div>

      <div className={`${breakpoints.md ? 'col-sm-6' : 'col-sm-4 col-6'} ministry-item`}>
        <div>
          <div className="ministry-image background-green m-b-16 small-m-b-10">
            <img
              src={imageSecond}
              alt={titleSecond}
              className="m-r--24"
            />
          </div>
        </div>
        <div>
          <h2 className="title ministry-title m-b-8"> {titleSecond} </h2>
          <div className="ministry-desc m-b-31 small-m-b-24">
          <div dangerouslySetInnerHTML={{__html: descSecond }} />
          </div>
          <Button2
           title={t("Les nostres missions")}
           className="r-more green right"
           //onClick={() => navigate(post?.items[0]?.node?.path?.alias)}
           />
        </div>
      </div>
      {!breakpoints.md &&
        <div className="col-sm-2"></div>
      }

    </div>
  )
}

const PageTopContentText = ({lang}) => {
  if(lang === "ca"){
    return(
      <div className="p-t-104">
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
           <div>
           </div>
      </div>
    )
  }else{
    return(
      <div>
          <div>“Sigui quin sigui el vostre treball, <span className="fill-backgrond">feu-lo de cor</span>, pel Senyor i no pels homes".
           </div>
      </div>
    )
  }
}



export const unFlattenResults = (results) =>{
  return results.map(post => {
    const { summary, alias, title } = post;
      return { alias, body: { title, summary }
    };
  });
}



 export default SchoolPage
