import React, { useContext } from "react"
import { LanguageContext } from "../../context"
import { Link, navigate } from "gatsby"
//import image from "../../assets/images/homepage/la-nostra-comunitat/img-1@3x.png"
import Button from "../button-generic"
import CategoryItem from "../categories/category-tag-item"
import Icon from "../icon"

 const LessonItem = (props) => {

   const {
     title,
     day,
     month,
     description,
     datehour,
     place,
     url,
     className,
     date,
     path,
     image,
     includeTags,
     categories
   } = props



   const { t } = useContext(LanguageContext)
   return (
           <div className={className}>
             <article
              className="blog-item-content"
              itemScope
              itemType="http://schema.org/Article"
              >
              <div style={{ maxWidth: `294px`, height:`192px!important`, width: `100%` }} className="m-b-16">
                <img
                  src={image}
                  alt={title}
                  className="article-image"
                />
              </div>
              <header className="p-l-16">
                <h2 className="blog-item-title m-b-5 m-b-16">
                  <Link to={path} itemProp="url">
                    <span itemProp="headline"> {title} </span>
                  </Link>
                </h2>
              </header>
              <section>
               <div className="blog-item-desc m-b-16 h5-light-l-black p-l-16">
                {description}
                </div>
                <Link
                  to={path}
                  className="r-more p-l-16"
                >
                Veure més
                <Icon
                  icon="icons-32-x-32-generic-plus"
                  size={16}
                  className="icon"
                />
                </Link>


                  {includeTags && categories &&
                    <CategoryItem
                      title={categories[0]?.name}
                      icon="icons-32-x-32-export-tag"
                      color="bordeaux"
                      path={categories[0]?.path?.alias}
                    />
                  }
              </section>
             </article>
           </div>
   )
 }

 export default LessonItem
